@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.app {
  /* width: 100vw; */
  min-height: 100vh;
  background: #fafafa;
  font-family: 'Inter', sans-serif;
}

header {
  min-height: 100px;
  text-align: center;
  display: flex;
  align-items: center;

  & > h1 {
    flex: 1;
  }
}

.genaie-logo {
  height: 50px;
  width: 140px;
  margin: 5px auto;
}

.main-container {
  min-height: calc(100vh - 200px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

main {
  width: calc(100vw - 200px);
  margin: 0 auto;
}
footer {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container {
  position: relative;
}
.footer-container > .footer-waves-horizontal {
  width: 100%;
  margin: 0;
  height: 100%;
  margin-top: 32px;
}

.footer-container > .footer-waves-corner {
  width: 30%;
  margin: 0;
  height: 100%;
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 25px;
}

.back-button-container {
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  & > a {
    color: #000;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
}

.back-button-container,
.single-app-container {
  width: 50vw;
  margin: 10px auto;
}

.single-app-container {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  background: #fff;
  padding: 24px;
  margin-top: 24px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  max-width: 1000px;
  margin: auto;
}

.card-link,
.card-link + .card-link {
  width: calc(33.33% - 32px);
  text-decoration: none;
  margin: 16px;
}

@media (max-width: 1024px) {
  .card-link,
  .card-link + .card-link {
    width: calc(50% - 32px);
  }
}

.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
}

.card-body {
  padding: 24px 32px;
  display: flex;
  align-items: center;
}
.card-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.card-title-text {
  text-align: center;
  color: #000;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.title {
  color: #000;
  font-family: Inter;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto 2rem;
  text-align: center;
}

.title-container {
  display: flex;
  align-items: center;
  margin: 48px auto;
  max-width: 50vw;
}

.back-route-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.back-route-container > p {
  margin: 0;
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
}

/* Input Styles */

.text-input {
  display: flex;
  height: 48px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid#c2c2c2;
  background: #fff;
  color: #757575;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-input :focus,
.text-input :focus {
  outline: none;
}

/* Slider styles */

.custom-slider {
  width: 100%;
}

.custom-slider::-webkit-slider-runnable-track {
  background-color: #e2e4e8;
  border-radius: 16px;
}

.custom-slider::-webkit-slider-thumb {
  background-color: #fff;
  border: 2px solid #6f2def;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.custom-slider::-webkit-slider-thumb:active {
  background-color: #fff;
  border: 2px solid #6f2def;
  outline: none;
}

.primary-btn-style {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #2781f2;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.secondary-btn-style {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #2781f2;
  color: #2781f2;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.file-input input[type='file'] {
  display: flex;
  border-radius: 4px;
  border: 1px solid#c2c2c2;
  background: #fff;
  color: #757575;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 12px;
}

.link-style {
  color: #2682f2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.abort-btn, .send-btn {
  width: 78px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logout-button {
  margin-right: 2rem;
  background: none;
  border: none;
  color: rgb(52, 121, 249);
}

.logout-button:hover {
  background: none;
  border: none;
  color: rgb(52, 121, 249);
}

.profile-icon {
  color: rgb(52, 121, 249);
}
.profile-icon:hover {
  color: rgb(21, 100, 247);
}
.profile-logout-container {
.btn.show {
  background: none;
  border: none;
}
.dropdown-toggle::after {
  display: none;
}

.logout-item:hover {
  font-weight:500;
}
}

.activity-button {
  display: flex;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: rgb(52, 121, 249);
  color: white;
}

.activity-button:hover {
  background-color: rgb(21, 100, 247);
}
.margin-top {
  margin-top: 32px;
}