.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 150px; */
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spinner-border {
  width: 150px;
  height: 150px;
}

.spinner-container:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.25), rgba(0, 0, 0, 0.25));
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
